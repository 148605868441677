.blog {
  padding-top: 125px;
  margin: auto;
}
.blog h2 {
  margin-bottom: 20px;
}
.blog .container {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  width: 90vw;
}
@media only screen and (max-width: 570px) {
  .blog .container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
