.project-card {
  width: 25vw;
  height: 32vh;
  box-shadow: 0px 0px 8px -4px #7a7a7a;
  background-color: #f3f3f3;
  padding-bottom: 50px;
  margin: 15px auto;
}
.project-card:hover {
  box-shadow: 0px 2px 9px -4px #7a7a7a;
}
.project-card .img-container {
  background-color: #fff;
  width: 25vw;
  height: 30vh;
  margin-bottom: 10px;
}
.project-card img {
  object-fit: contain;
  height: 30vh;
  width: 25vw;
}
.project-card .content-card {
  display: flex;
  flex-direction: column;
}
.project-card .content-card button:hover {
  cursor: pointer;
}
.project-card a {
  text-decoration: none;
}
.project-card a:hover {
  text-decoration: underline;
  text-decoration-color: #7a7a7a;
}
.project-card .delete-card {
  position: absolute;
  margin-left: 20px;
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .project-card {
    width: 70vw;
    height: 35vh;
    margin: 10px 0;
    padding-bottom: 20px;
  }
  .project-card .img-container {
    width: 70vw;
  }
  .project-card img {
    width: 70vw;
    height: 30vh;
  }
  .project-card p {
    font-size: 12px;
  }
  .project-card h3 {
    font-size: 16px;
  }
}
