.login {
  padding-top: 125px;
}
.login h2 {
  margin-bottom: 20px;
}
.login form {
  margin: auto;
  display: flex;
  flex-direction: column;
}
.login form button {
  margin: 50px auto;
  width: 90px;
}
.login span {
  display: block;
}
