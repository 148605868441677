.project {
  padding-top: 125px;
  margin: 0px 80px;
}
.project h2 {
  margin-bottom: 20px;
}
.project .container {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 570px) {
  .project {
    margin: 0px auto;
  }
  .project .container {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 20px;
  }
}
