.project-details {
  padding-top: 115px;
}
.project-details .container {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}
.project-details .container img {
  /* width: 60vw; */
  height: 60vh;
  box-shadow: 0px 0px 8px -4px #7a7a7a;
}
.project-details .container .detailed {
  box-shadow: 0px 0px 8px -4px #7a7a7a;
  width: 25vw;
  height: 50vh;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
.project-details .container .detailed .detailed-detail {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
}
.project-details .container .detailed .detailed-detail p {
  margin: 20px auto;
}
.project-details .container .detailed .detailed-detail a {
  background-color: #a2dbfa;
  align-self: center;
  width: 20vh;
  padding: 5px;
  text-decoration: none;
  color: #7a7a7a;
}
.project-details .container .detailed .detailed-detail a:hover {
  box-shadow: 0px 0px 8px -4px #7a7a7a;
  color: gray;
}
.project-details .more-detail img {
  box-shadow: none;
  width: 60px;
  height: 60px;
}
.project-details .detailed p {
  height: 35vh;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .project-details .container img {
    width: 70vw;
    height: 20vh;
    margin: auto;
  }
  .project-details .container {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
  }
  .project-details .container .detailed {
    width: 80vw;
    height: 40vh;
    margin: auto;
  }
}
@media only screen and (max-width: 968px) {
  .project-details .container {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
  }
  .project-details .container img {
    width: 90vw;
    height: 28vh;
    margin: auto;
  }
  .project-details .container .detailed {
    width: 75vw;
    height: 40vh;
    margin: auto;
  }
  .project-details .more-detail img {
    width: 50px;
    height: 50px;
  }
}
