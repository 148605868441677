.navbar {
  position: fixed;
  width: 100%;
  z-index: 99;
  display: block;
  background-color: #fff;
}
.navbar ul {
  margin: 20px 50px;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}
.navbar a {
  color: #7a7a7a;
  text-decoration: none;
  font-size: 1.2em;
}
.navbar li:nth-child(2) a {
  margin: 0 10px;
}
.navbar a:hover {
  color: black;
}

.navbar a.active {
  padding: 0 5px;
  border: 1px solid #3c4448;
  color: #000000;
}
.navbar .hidden-menu {
  display: none;
}
.navbar .nav-icon1 {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 30px;
  display: none;
}
.navbar button:hover {
  cursor: pointer;
  background-color: #e4e4e4;
}
.navbar button {
  padding: 8px;
  font-size: 14px;
  background-color: #eee;
  border: none;
}

@media only screen and (max-width: 570px) {
  .navbar li:nth-child(2) {
    display: none;
  }
  .navbar ul {
    margin: 20px 0px;
    margin-left: 40px;
  }
  .navbar .hidden-menu {
    display: none;
  }
  .navbar .hidden-menu.active {
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  .navbar .hidden-menu li {
    font-size: 24px;
    display: flex;
    flex-direction: column;
  }
  .navbar img {
    display: inline;
    transition: 1s;
  }
  .navbar img:active {
    background-color: #e8f0f2;
  }

  /* hamburger */
  .navbar .nav-icon1 {
    display: inline;
    width: 20px;
    height: 8px;
    position: relative;
    margin: -13px 50px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .nav-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 6vw;
    background: #7a7a7a;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .nav-icon1 span:nth-child(1) {
    top: 0px;
  }

  .nav-icon1 span:nth-child(2) {
    top: 7px;
  }

  .nav-icon1 span:nth-child(3) {
    top: 14px;
  }

  .nav-icon1.active span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .nav-icon1.active span:nth-child(2) {
    opacity: 0;
    left: -20px;
  }

  .nav-icon1.active span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}

@keyframes scale-up-top {
  0% {
    transform: scale(0.5);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
