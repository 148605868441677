.blog-card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  margin: 15px auto;
  padding: 20px 30px;
  box-shadow: 0px 0px 8px -4px #7a7a7a;
  width: 80vw;
  height: 30vh;
  text-align: start;
}
.blog-card:hover {
  box-shadow: 0px 2px 9px -4px #7a7a7a;
}
.blog-card a {
  text-decoration: none;
  font-size: 18px;
}
.blog-card button:hover {
  cursor: pointer;
}
.blog-card .delete-card {
  margin-bottom: auto;
  color: red;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 20px;
}
.blog-card a:hover {
  text-decoration: underline;
  text-decoration-color: #7a7a7a;
}
.blog-card h3 {
  font-weight: normal;
  padding: 10px;
  font-weight: 700;
  margin-bottom: 20px;
}
.blog-tag {
  display: flex;
}
.blog-tag p {
  padding: 3px;
  margin-left: 15px;
  border-radius: 2px;
  width: fit-content;
  background-color: #a2dbfa;
}
.blog-card .createdAt {
  font-size: 14px;
  width: fit-content;
}
.blog-card .createdAt p {
  margin-left: 12px;
}
.blog-card .content {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog-card .picture-container img {
  max-width: 225px;
  max-height: 194px;
}
@media only screen and (max-width: 570px) {
  .blog-card {
    width: 75vw;
    flex-direction: column;
    padding: 30px 20px;
    height: fit-content;
  }
}
