@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --heading-color: black;
  --text-color: #7a7a7a;
  --primary-color: #a2dbfa;
  --highlight-color: #80ed99;
  --bg-color: #e8f0f2;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}
span {
  margin: 20px 10px 20px 0px;
  font-weight: 500;
  color: #7a7a7a;
}
h1,
h2,
h3,
h4,
p,
ul {
  padding: 0;
  margin: 0;
  color: #7a7a7a;
}
input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 50vw;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
textarea {
  min-height: 180px;
}
.btn {
  font-size: 24px;
  background-color: #fff;
  border: 1px solid #2d3132;
  padding: 10px;
}
.btn:hover {
  background-color: #d0e6ea;
  cursor: pointer;
}
.error {
  color: rgb(236, 172, 172);
  background: pink;
  border: 1px solid rgb(219, 154, 154);
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* scrrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media only screen and (max-width: 570px) {
  input,
  textarea {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 70vw;
    box-sizing: border-box;
    border: 1px solid #ddd;
  }
  textarea {
    min-height: 180px;
  }
}
