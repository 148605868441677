.create .createtitle {
  padding-top: 125px;
  display: flex;
  justify-content: center;
}
.createimg form {
  margin: auto;
  width: 700px;
}

.createimg span {
  display: block;
}

.blogproject {
  margin-left: 5px;
  border: none;
  background: none;
  font-size: 24px;
  color: #7a7a7a;
}
.createblog span {
  display: block;
}
.createproject span {
  display: block;
}
.create .tagContainer {
  display: flex;
  margin: 5px auto;
  justify-content: center;
  align-items: flex-end;
  width: fit-content;
  gap: 20px;
}
.create .tagContainer input {
  width: 200px;
}
.create .tagContainer button {
  height: fit-content;
  padding: 5px;
}
.createblog form {
  margin: auto;
  width: 700px;
}
.createproject harus-pendek {
  width: 20px;
}
@media only screen and (max-width: 570px) {
  .createblog form {
    width: 400px;
  }
}
