.contact {
  width: 80vw;
  padding-top: 125px;
  margin: 0px auto;
}
.contact h2 {
  margin-bottom: 20px;
}
.contact .container {
  display: flex;
}
.contact .kiri {
  padding-left: 20px;
  width: 50vw;
  text-align: start;
}
.contact .kiri p {
  padding-left: 0;
  margin-bottom: 20px;
}
.contact .kanan {
  margin: auto 0 auto 180px;
}
.contact .kanan img {
  width: 60px;
  margin: 10px;
}
@media only screen and (max-width: 570px) {
  .contact .container {
    flex-direction: column;
  }
  .contact .kiri {
    width: 70vw;
  }
  .contact .kanan {
    margin: 40px 10px;
  }
}
