.blog-detail {
  padding-top: 125px;
  width: 85vw;
  margin: auto;
}
.blog-detail .content-detail {
  width: 80vw;
  text-align: start;
  color: black;
  margin: 20px auto;
}
.blog-detail .content-detail p {
  margin-bottom: 0;
}
.blog-detail p {
  text-align: start;
  margin-bottom: 70px;
}
.blog-detail img {
  width: 100%;
  height: 100%;
  margin: auto;
}
.blog-detail h2:first-child {
  text-align: start;
  padding-bottom: 5px;
  border-bottom: 1px solid #7a7a7a;
}
