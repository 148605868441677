.home {
  padding-top: 115px;
  display: flex;
  flex-direction: column;
}
.home .home-container {
  display: flex;
  flex-direction: row;
}
.home h4 {
  text-align: start;
  font-size: 36px;
  line-height: 36px;
  color: #000000;
}
.home h3 {
  color: #000000;
}
.home .kiri p {
  text-align: start;
  font-size: 20px;
  margin-top: 36px;
  color: #7a7a7a;
}
.home .kiri a {
  text-decoration: none;
  color: black;
}
.home .kiri .btn-div {
  display: flex;
  width: 18vw;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 12px;
}
.home button {
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #2d3132;
}
.home button:hover {
  background-color: #d0e6ea;
  cursor: pointer;
}
.home .tengah {
  padding-top: 20px;
  width: 40vw;
  /* height: 50vh; */
}
.home .tengah .p {
  bottom: 0;
  right: 0;
  margin: 0 50px 50px 0;
  position: absolute;
  width: 70px;
  height: 70px;
}
.home .tengah .p:hover {
  cursor: pointer;
}
.home .tengah .p .next {
  padding-top: 10px;
  margin: auto;
  border-radius: unset;
}
.home .tengah img {
  margin: auto;
  margin-left: 50px;
  border-radius: 50%;
  max-width: 400px;
  background-color: #d0e6ea;
}
.home .kiri .btn {
  padding: 5px;
}
.home .kiri {
  width: 45vw;
  padding: 32px 32px 0px 80px;
}
.home .kanan {
  text-align: start;
  padding: 50px 0 0 50px;
  margin-right: 50px;
}
.home .kiri-hidden {
  display: none;
}
.home .kanan-hidden {
  display: none;
}
.home .content-foto {
  padding-top: 30px;
  margin-bottom: 50px;
}
.home .kanan h3 {
  margin-top: 40px;
}
.home .content-foto img {
  width: 60px;
  padding: 10px;
  box-shadow: 0px 0px 8px -4px #7a7a7a;
  margin: auto 5px;
}

@media only screen and (max-width: 720px) {
  .home {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
  }
  .home .home-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .home .kiri {
    width: 78vw;
    padding: 0;
    margin: 0 40px;
    order: 1;
  }
  .home .tengah {
    width: 100vw;
    order: 2;
  }
  .home .kanan {
    order: 1;
  }
  .home .tengah img {
    margin: 20px auto;
    border-radius: 50%;
    max-width: 260px;
  }
}
